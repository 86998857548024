import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient';
import IGetAllCategoryResponse from 'services/httpClient/responses/category/IGetAllCategoryResponse';

import { categoryActions } from '..';

function* getAll() {
  try {
    const response: AxiosResponse<IGetAllCategoryResponse> = yield call(
      httpClient.get,
      'admins/categories',
    );
    yield put(
      categoryActions.getAllSuccess({
        list: response.data.list,
      }),
    );
  } catch (err) {
    yield put(categoryActions.getAllFailure());
  }
}

export default getAll;
