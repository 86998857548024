import categorySelectedIds from './categorySelectedIds';
import getAll from './getAll';
import getAllIsLoading from './getAllIsLoading';

const categorySelectors = {
  getAll,
  getAllIsLoading,
  categorySelectedIds,
};

export default categorySelectors;
