import { CSSProperties } from 'react';

import ICategory from 'models/ICategory';

import {
  Checkbox,
  CheckboxInput,
  CheckboxMark,
  Container,
  Name,
} from './styles';

interface IProps {
  categories: ICategory[];
  index: number;
  isLoading: boolean;
  onToggleCheckbox?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
}

const CategoryItem = ({
  categories,
  index,
  isLoading,
  onToggleCheckbox,
  style,
}: IProps): JSX.Element => {
  return (
    <Container data-tut="filter-item" style={style}>
      <Name>{categories[index]?.name}</Name>
      <Checkbox>
        <CheckboxInput
          checked={categories[index]?.checked}
          className="checkboxInput"
          disabled={isLoading}
          onChange={onToggleCheckbox}
          type="checkbox"
        />
        <CheckboxMark className="checkmark" />
      </Checkbox>
    </Container>
  );
};

export default CategoryItem;
