import { authReducers, IAuthData } from './slices/auth';
import { categoryReducers, ICategoryData } from './slices/category';
import { IManufacturerData, manufacturerReducers } from './slices/manufacturer';
import { IProductData, productReducers } from './slices/product';

export interface IApplicationState {
  auth: IAuthData;
  category: ICategoryData;
  manufacturer: IManufacturerData;
  product: IProductData;
}

const rootReducers = {
  auth: authReducers,
  manufacturer: manufacturerReducers,
  product: productReducers,
  category: categoryReducers,
};

export default rootReducers;
