import { Draft, PayloadAction } from '@reduxjs/toolkit';

import IGetAllCategoryResponse from 'services/httpClient/responses/category/IGetAllCategoryResponse';

import { ICategoryData } from '../..';

function getAllSuccess(
  draft: Draft<ICategoryData>,
  action: PayloadAction<IGetAllCategoryResponse>,
) {
  draft.getAll.config.isLoading = false;
  draft.getAll.list = action.payload.list.map(category => ({
    ...category,
    checked: false,
  }));
}

export default getAllSuccess;
