import { CSSProperties } from 'react';

import IManufacturer from 'models/Manufacturer';

import {
  Checkbox,
  CheckboxInput,
  CheckboxMark,
  Container,
  Name,
} from './styles';

interface IManufacturerItemProps {
  index: number;
  isLoading: boolean;
  manufacturers: IManufacturer[];
  onToggleCheckbox?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
}

const ManufacturerItem = ({
  index,
  isLoading,
  manufacturers,
  onToggleCheckbox,
  style,
}: IManufacturerItemProps): JSX.Element => {
  return (
    <Container data-tut="filter-item" style={style}>
      <Name>{manufacturers[index].name}</Name>
      <Checkbox>
        <CheckboxInput
          checked={manufacturers[index].checked}
          className="checkboxInput"
          disabled={isLoading}
          onChange={onToggleCheckbox}
          type="checkbox"
        />
        <CheckboxMark className="checkmark" />
      </Checkbox>
    </Container>
  );
};

export default ManufacturerItem;
