import { createSlice } from '@reduxjs/toolkit';

import reducers from './reducers';

export interface ICategory {
  checked: boolean;
  id: number;
  name: string;
}

export interface ICategoryData {
  checkedIds: number[];
  getAll: {
    config: {
      isLoading: boolean;
    };
    list: ICategory[];
  };
}

const initialState: ICategoryData = {
  checkedIds: [],
  getAll: {
    config: {
      isLoading: false,
    },
    list: [],
  },
};

const categorySlice = createSlice({
  name: '@category',
  initialState,
  reducers,
});

export const categoryActions = categorySlice.actions;
export const categoryReducers = categorySlice.reducer;
