import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICategoryData } from '../../index';

interface ISetToggleCheckedCategoryAction {
  data: {
    id: number;
  };
}

function setToggleCheck(
  draft: Draft<ICategoryData>,
  action: PayloadAction<ISetToggleCheckedCategoryAction>,
) {
  draft.getAll.list = draft.getAll.list.map(item => {
    if (item.id === action.payload.data.id) {
      return {
        ...item,
        checked: !item.checked,
      };
    }
    return item;
  });
  draft.checkedIds = draft.getAll.list
    .filter(category => category.checked)
    .map(categoryFiltered => categoryFiltered.id);
}

export default setToggleCheck;
