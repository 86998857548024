import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import EHttpStatusCodes from 'enums/httpStatusCodes';
import QueryString from 'qs';
import { call, put } from 'redux-saga/effects';

import httpClient from 'services/httpClient/index';
import IGetAllProductRequest from 'services/httpClient/request/product/IGetAllProductRequest';
import IGetAllProductResponse from 'services/httpClient/responses/product/IGetAllProductResponse';

import { productActions } from '../index';
import { IGetAllProductRequestAction } from '../reducers/getAll/request';

function* getAll(action: PayloadAction<IGetAllProductRequestAction>) {
  try {
    const params: IGetAllProductRequest = {
      nameOrBarcodeOrSku: action.payload.data.nameOrBarcodeOrSku || undefined,
      page: action.payload.data.page,
      manufacturerIds: action.payload.data.manufacturerIds || undefined,
      perPage: 50,
      categoryIds: action.payload.data.categoryIds || undefined,
    };

    const response: AxiosResponse<IGetAllProductResponse> = yield call(
      httpClient.get,
      'webs/products',
      {
        params,
        paramsSerializer: params => {
          return QueryString.stringify(params);
        },
      },
    );
    if (response.status === EHttpStatusCodes.NO_CONTENT) {
      yield put(
        productActions.getAllSuccessWithoutData({
          message: 'Nenhum produto encontrado',
        }),
      );
      return;
    }
    yield put(
      productActions.getAllSuccessWithData({
        list: response.data.list,
        totalPages: response.data.totalPages || 1,
        totalProducts: response.data.totalProducts,
      }),
    );
  } catch (err) {
    action.payload.functions.error(err);
    yield put(productActions.getAllFailure());
  }
}

export default getAll;
