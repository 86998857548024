import { Draft, PayloadAction } from '@reduxjs/toolkit';

import { ICategoryData } from '../../index';

interface ISetToggleCheckAllCategoryAction {
  data: {
    value: boolean;
  };
}

function setToggleCheckAll(
  draft: Draft<ICategoryData>,
  action: PayloadAction<ISetToggleCheckAllCategoryAction>,
) {
  draft.getAll.list = draft.getAll.list.map(item => ({
    ...item,
    checked: action.payload.data.value,
  }));
  draft.checkedIds = draft.getAll.list
    .filter(category => category.checked)
    .map(categoryFiltered => categoryFiltered.id);
}

export default setToggleCheckAll;
