import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ScaleLoader } from 'react-spinners';
import { AutoSizer, List } from 'react-virtualized';

import { FormikProps } from 'formik';
import ICategory from 'models/ICategory';
import IManufacturer from 'models/Manufacturer';

import { useReduxDispatch } from 'hooks/useReduxDispatch';
import { useReduxSelector } from 'hooks/useReduxSelector';
import useToast from 'hooks/useToast';

import ComponentIsVisible from 'components/utils/IsVisible';

import { categoryActions } from 'store/slices/category';
import categorySelectors from 'store/slices/category/selectors';
import { manufacturerActions } from 'store/slices/manufacturer';
import manufacturerSelectors from 'store/slices/manufacturer/selectors';
import { productActions } from 'store/slices/product';
import productSelectors from 'store/slices/product/selectors';

import helpers from 'helpers';

import colors from 'styles/colors';

import { IProductSearchFilterFormData } from '../Search';
import ManufacturerItem from './Item';
import CategoryItem from './Item/CategoryItem';
import {
  Categories,
  Container,
  EmptyManufacturers,
  FiltersContainer,
  FiltersTitle,
  GeneratePdfButton,
  LineSeparation,
  Manufacturers,
  ProductsQuantityWarning,
  Title,
} from './styles';

interface IProductManufacturerFilterProps {
  productIsLoading: boolean;
  searchBarRef: React.RefObject<FormikProps<IProductSearchFilterFormData>>;
}

const ProductManufacturerFilter = ({
  productIsLoading,
  searchBarRef,
}: IProductManufacturerFilterProps): JSX.Element => {
  const toast = useToast();
  const reduxDispatch = useReduxDispatch();

  const manufacturers = useReduxSelector(manufacturerSelectors.getAllList);
  const emptyManufacturers = useReduxSelector(
    manufacturerSelectors.getAllEmptyMessage,
  );
  const totalProducts = useReduxSelector(productSelectors.getAllTotalProducts);
  const isLoading = useReduxSelector(manufacturerSelectors.getAllIsLoading);
  const categoriesIsLoading = useReduxSelector(
    categorySelectors.getAllIsLoading,
  );
  const productsToPdfIsLoading = useReduxSelector(
    manufacturerSelectors.getProductsToPdfIsLoading,
  );
  const manufacturerCheckedIds = useReduxSelector(
    manufacturerSelectors.manufacturerCheckedIds,
  );
  const categoryCheckedIds = useReduxSelector(
    categorySelectors.categorySelectedIds,
  );
  const categories = useReduxSelector(categorySelectors.getAll);

  const [selectAllManufacturerOptions, setSelectAllManufacturerOptions] =
    useState<IManufacturer[]>([
      {
        checked: false,
        id: 0,
        name: 'Selecionar todos',
      },
    ]);
  const [selectAllCategoriesOptions, setSelectAllCategoriesOptions] = useState<
    ICategory[]
  >([
    {
      checked: false,
      id: 0,
      name: 'Selecionar todos',
    },
  ]);

  const listRef = useRef<List>(null);

  const loadManufacturers = useCallback((): void => {
    reduxDispatch(
      manufacturerActions.getAllRequest({
        functions: {
          error: (err: any) => {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [reduxDispatch]);

  const loadCategories = useCallback((): void => {
    reduxDispatch(categoryActions.getAllRequest());
  }, [reduxDispatch]);

  const handleToggleChecked = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      manufacturerId: number,
    ): void => {
      if (manufacturerId === 0) {
        reduxDispatch(
          manufacturerActions.setToggleCheckAll({
            data: {
              value: event.target.checked,
            },
          }),
        );
        setSelectAllManufacturerOptions(manufacturerOptions => {
          const newManufacturerOptions = manufacturerOptions.map(
            manufacturerOption => ({
              ...manufacturerOption,
              checked: !manufacturerOption.checked,
            }),
          );
          return newManufacturerOptions;
        });
        return;
      }
      reduxDispatch(
        manufacturerActions.setToggleCheck({
          data: {
            id: manufacturerId,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const handleCategoryToggleChecked = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, categoryId: number): void => {
      if (categoryId === 0) {
        reduxDispatch(
          categoryActions.setToggleCheckAll({
            data: {
              value: event.target.checked,
            },
          }),
        );
        setSelectAllCategoriesOptions(categoryOptions => {
          const newManufacturerOptions = categoryOptions.map(
            categoryOption => ({
              ...categoryOption,
              checked: !categoryOption.checked,
            }),
          );
          return newManufacturerOptions;
        });
        return;
      }
      reduxDispatch(
        categoryActions.setToggleCheck({
          data: {
            id: categoryId,
          },
        }),
      );
    },
    [reduxDispatch],
  );

  const hasManufacturerSelected = useMemo((): boolean => {
    const manufacturersFiltered = manufacturers.filter(
      manufacturer => manufacturer.checked === true,
    );
    return manufacturersFiltered.length >= 1;
  }, [manufacturers]);

  const hasCategoriesSelected = useMemo((): boolean => {
    const categoriesFiltered = categories.filter(
      category => category.checked === true,
    );
    return categoriesFiltered.length >= 1;
  }, [categories]);

  const MemoizedComponentEmpty = useCallback(
    (): JSX.Element => (
      <EmptyManufacturers show={!!emptyManufacturers}>
        Nenhum fabricante adicionado
      </EmptyManufacturers>
    ),
    [emptyManufacturers],
  );

  const MemoizedManufacturerItem = useCallback(
    ({ index, key, style }): JSX.Element => (
      <ManufacturerItem
        index={index}
        isLoading={isLoading || productIsLoading}
        key={key}
        manufacturers={manufacturers}
        onToggleCheckbox={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleToggleChecked(event, manufacturers[index].id)
        }
        style={style}
      />
    ),
    [handleToggleChecked, isLoading, manufacturers, productIsLoading],
  );

  const MemoizedCategoryItem = useCallback(
    ({ index, key, style }): JSX.Element => (
      <CategoryItem
        categories={categories}
        index={index}
        isLoading={categoriesIsLoading || productIsLoading}
        key={key}
        onToggleCheckbox={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleCategoryToggleChecked(event, categories[index].id)
        }
        style={style}
      />
    ),
    [
      categories,
      categoriesIsLoading,
      handleCategoryToggleChecked,
      productIsLoading,
    ],
  );

  const handleGeneratePdf = useCallback((): void => {
    const manufacturersFilteredIds = manufacturers
      .filter(manufacturer => manufacturer.checked)
      .map(manufacturerFiltered => manufacturerFiltered.id);
    const categoriesFilteredIds = categories
      .filter(category => category.checked)
      .map(categoryFiltered => categoryFiltered.id);
    reduxDispatch(
      manufacturerActions.getProductsToPdfRequest({
        data: {
          ids: manufacturersFilteredIds,
          categoryIds: categoriesFilteredIds,
        },
        functions: {
          error: (err: any) => {
            helpers.errorHandling(err);
          },
          success: (message: string) => {
            toast.show({
              title: message,
              type: 'success',
            });
          },
        },
      }),
    );
  }, [categories, manufacturers, reduxDispatch, toast]);

  const virtualizedListHeight = useMemo(() => {
    const windowWidth = window.innerWidth;
    const listHeight = windowWidth >= 1024 ? 35 : 30;
    return listHeight;
  }, []);

  useEffect(() => {
    loadManufacturers();
    loadCategories();
  }, [loadCategories, loadManufacturers]);

  useEffect(() => {
    reduxDispatch(
      productActions.getAllRequest({
        data: {
          categoryIds: categoryCheckedIds,
          manufacturerIds: manufacturerCheckedIds,
          page: 1,
          nameOrBarcodeOrSku: searchBarRef.current?.values.nameOrBarcodeOrSku,
        },
        functions: {
          error: (err: any) => {
            helpers.errorHandling(err);
          },
        },
      }),
    );
  }, [manufacturerCheckedIds, reduxDispatch, searchBarRef, categoryCheckedIds]);

  return (
    <Container>
      <FiltersContainer>
        <FiltersTitle>Filtros</FiltersTitle>
        <div>
          <Title>Fabricantes</Title>
          <Manufacturers>
            <ComponentIsVisible when={!!manufacturers.length}>
              <ManufacturerItem
                index={0}
                isLoading={isLoading || productIsLoading}
                manufacturers={selectAllManufacturerOptions}
                onToggleCheckbox={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ) => handleToggleChecked(event, 0)}
              />
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    autoContainerWidth
                    className="virtualizedListScrollBar"
                    height={virtualizedListHeight * 12}
                    noRowsRenderer={MemoizedComponentEmpty}
                    overscanRowCount={8}
                    ref={listRef}
                    rowCount={manufacturers.length}
                    rowHeight={virtualizedListHeight}
                    rowRenderer={MemoizedManufacturerItem}
                    width={width}
                  />
                )}
              </AutoSizer>
            </ComponentIsVisible>
          </Manufacturers>
        </div>
        <LineSeparation />
        <div>
          <Title>Categorias</Title>
          <Categories>
            <ComponentIsVisible when={!!categories.length}>
              <CategoryItem
                categories={selectAllCategoriesOptions}
                index={0}
                isLoading={categoriesIsLoading || productIsLoading}
                onToggleCheckbox={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ) => handleCategoryToggleChecked(event, 0)}
              />
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    autoContainerWidth
                    className="virtualizedListScrollBar"
                    height={virtualizedListHeight * 12}
                    noRowsRenderer={MemoizedComponentEmpty}
                    overscanRowCount={8}
                    ref={listRef}
                    rowCount={categories.length}
                    rowHeight={virtualizedListHeight}
                    rowRenderer={MemoizedCategoryItem}
                    width={width}
                  />
                )}
              </AutoSizer>
            </ComponentIsVisible>
          </Categories>
        </div>
      </FiltersContainer>
      {(hasManufacturerSelected || hasCategoriesSelected) &&
      totalProducts <= 1000 ? (
        <GeneratePdfButton
          disabled={productsToPdfIsLoading}
          onClick={handleGeneratePdf}
        >
          <ComponentIsVisible when={!productsToPdfIsLoading}>
            Gerar Pdf
          </ComponentIsVisible>
          <ComponentIsVisible when={productsToPdfIsLoading}>
            <ScaleLoader color={colors.orange900} height={15} width={2} />
          </ComponentIsVisible>
        </GeneratePdfButton>
      ) : (
        <ProductsQuantityWarning>
          Permitido apenas 1000 produtos para gerar o PDF
        </ProductsQuantityWarning>
      )}
    </Container>
  );
};

export default ProductManufacturerFilter;
