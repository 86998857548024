import styled from 'styled-components';

import { shade } from 'polished';

import colors from 'styles/colors';

export const Container = styled.div`
  background-color: ${colors.white900};
  border: 1px solid ${colors.gray100};
  border-radius: 1rem;
  display: grid;
  height: fit-content;
  padding: 1rem 0 1rem 0.5rem;
`;

export const Title = styled.h5`
  color: ${colors.gray900};
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const Manufacturers = styled.div`
  display: grid;
  height: 7.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.6s ease 0s;
  ::-webkit-scrollbar {
    background: ${shade(0.1, colors.orange900)};
    height: 0.5rem;
    width: 0.2rem;
  }
  ::-webkit-scrollbar-track {
    background: ${colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background: ${shade(0.1, colors.orange900)};
    height: 0.5rem;
    width: 0.75rem;
  }
  @media screen and (min-width: 1024px) {
    height: 15rem;
  }
`;

export const Categories = styled.div`
  display: grid;
  height: 7.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.6s ease 0s;
  ::-webkit-scrollbar {
    background: ${shade(0.1, colors.orange900)};
    height: 0.5rem;
    width: 0.2rem;
  }
  ::-webkit-scrollbar-track {
    background: ${colors.background};
  }
  ::-webkit-scrollbar-thumb {
    background: ${shade(0.1, colors.orange900)};
    height: 0.5rem;
    width: 0.75rem;
  }
  @media screen and (min-width: 1024px) {
    height: 15rem;
  }
`;

interface IEmptyManufacturersProps {
  show?: boolean;
}

export const EmptyManufacturers = styled.span<IEmptyManufacturersProps>`
  color: ${colors.gray300};
  font-size: 0.75rem;
  padding: 2rem 0.75rem 0;
  text-align: center;
`;

export const GeneratePdfButton = styled.button`
  background-color: ${colors.transparent};
  border: 0;
  color: ${colors.orange900};
  display: flex;
  font-size: 0.925rem;
  font-weight: 600;
  margin: 1rem auto 0;
  width: fit-content;

  &:hover {
    opacity: 0.75;
  }
`;

export const ProductsQuantityWarning = styled.p`
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
`;

export const FiltersContainer = styled.div`
  @media screen and (max-width: 1024px) {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const FiltersTitle = styled.h1`
  color: ${colors.orange900};
  font-size: 1.25rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const LineSeparation = styled.div`
  background-color: ${colors.backgroundDark};
  height: 1px;
  margin: 1rem 0.5rem 1rem 0;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
