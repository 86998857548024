import getAllFailure from './getAll/failure';
import getAllRequest from './getAll/request';
import getAllSuccess from './getAll/success';
import setToggleCheck from './set/toggleCheck';
import setToggleCheckAll from './set/toggleCheckAll';

const reducers = {
  getAllFailure,
  getAllRequest,
  getAllSuccess,
  setToggleCheck,
  setToggleCheckAll,
};

export default reducers;
